<template>
  <v-row>
    <slot></slot>
    <form-modal
      v-model="openModal"
      :cols="modalCols"
      title="Duplicar quantos meses a frente?"
      :value.sync="dataModal"
      @save="createMoreDepre"
    />
    <v-col :cols="12">
      <v-btn
        class="btn-plus-depre"
        depressed
        @click="createDepre()"
      >
        <v-icon>mdi-plus</v-icon>
        Nova Depreciação
      </v-btn>
    </v-col>
    <v-col v-if="!items" :cols="12">
      <span>Nenhuma depreciação cadastrada neste item.</span>
    </v-col>
    <expansion-panel
      v-else
      :contentProps="{ eager: true }"
      :headerProps="{ color: '#9abfe6' }"
      :items="items"
      :panelProps="panelProps"
    >
      <template v-slot:header="{ item, index }">
        <span>{{ getDepreciationTitle(item) }}</span>
        <span v-if="errors.has(index)" class="has-erros">
          {{ getErrors(index) }}
        </span>
        <v-btn
          class="icon-btn-title"
          depressed
          icon
          title="Duplicar depreciação para próxima competência"
          @click.stop="openDepreModal(item)"
        >
          <v-icon>mdi-content-duplicate</v-icon>
        </v-btn>
        <template v-if="canDelete">
          <v-btn
            v-if="item.delete"
            class="icon-btn-title"
            depressed
            icon
            title="Desfazer exclusão"
            @click.stop="deleteDepre(item, true)"
          >
            <v-icon>mdi-undo-variant</v-icon>
          </v-btn>
          <v-btn
            v-else
            class="icon-btn-title"
            depressed
            icon
            title="Excluir depreciação"
            @click.stop="deleteDepre(item)"
          >
            <v-icon>mdi-trash-can</v-icon>
          </v-btn>
        </template>
      </template>
      <template v-slot:content="{ item, index }">
        <v-col :cols="12">
          <depreciation-form
            :projetos="getProjects(item.competencia)"
            :value="item"
            @competencia="competenciaHandler($event)"
            @input="inputHandler(index, $event)"
            @update:valid="setValids(index, $event)"
          ></depreciation-form>
        </v-col>
      </template>
    </expansion-panel>
  </v-row>
</template>

<script>
import { cloneDeep } from "lodash";
import moment from "moment";
import toMonth from "@/helpers/toMonth";

export default {
  components: {
    "depreciation-form": () => import("./depreciation.form.vue"),
    "expansion-panel": () => import("@/components/expansion-panel.vue"),
    "form-modal": () => import("@/components/form-modal.vue"),
  },
  computed: {
    modalCols: function () {
      return [
        {
          key: "qtd",
          name: "Quantidade",
          type: this.$fieldTypes.NUMBER,
          rules: [{ rule: "min", params: { size: 1 }}],
        },
      ];
    },
    panelProps: function () {
      return (item) => ({
        disabled: item.delete,
      });
    },
  },
  created: function () {
    this.$emit('update:valid', this.generateUpdateValid());
  },
  data: function () {
    return {
      openModal: false,
      dataModal: { qtd: 1 },
      selectedItem: {},
      errors: new Map(),
      valids: new Map(),
    };
  },
  methods: {
    competenciaHandler: function (competencia = '') {
      const ano = competencia.substring(0, 4);
      const projeto = this.projectsByYear[ano];

      if (ano.length < 4) {
        return;
      }

      if (!Array.isArray(projeto) || projeto.length === 0) {
        this.$emit('loadProjectsFromYear', ano);
      }
    },
    createDepre: function () {
      const depre = {
        projetoId: null,
        competencia: moment().format("YYYY-MM"),
        justificativa: "",
      };
      this.items.push(depre);
      this.$emit('input', this.items);
      this.competenciaHandler(depre.competencia);
    },
    createMoreDepre: function ({ qtd }, closeFn) {
      console.log(qtd);
      const depre = {
        projetoId: null,
        competencia: moment().format("YYYY-MM"),
        justificativa: "",
        ...this.selectedItem,
      };
      let currComp = moment(depre.competencia);
      currComp = currComp.isValid() ? currComp : moment();
      const competenciaFormatada = currComp.format("MM/YYYY");
      const nextDepres = [];

      for (let i = 0; i < qtd; i++) {
        const nextDepre = cloneDeep(depre);
        const nextComp = currComp.add(1, "month");
        nextDepre.competencia = nextComp.format("YYYY-MM");
        nextDepre.justificativa = `Duplicado da competência ${competenciaFormatada}.\n${depre.justificativa}`;
        delete nextDepre.id;
        this.competenciaHandler(nextDepre.competencia);
        nextDepres.push(nextDepre);
      }

      let index = this.items.indexOf(this.selectedItem);
      index = index < 0 ? this.items.length - 1 : index + 1;

      this.items.splice(index, 0, ...nextDepres);
      this.selectedItem = {};
      this.$emit('input', this.items);
      closeFn();
    },
    deleteDepre: function (depreRef, restore = false) {
      const index = this.items.indexOf(depreRef);
      depreRef.delete = !restore;
      this.items.splice(index, 1, depreRef);
      this.$emit('input', this.items); // TODO será que só assim vai subir a alteração para cima??
    },
    generateUpdateValid: function () {
      const self = this;
      return () => {
        const valids = Array.from(self.valids.entries());
        const numErrors = valids.reduce((acc, [index, fn]) => {
          if (self.items[index].delete || typeof fn !== 'function') {
            return acc;
          }

          const num = fn()?.length || 0;
          return acc + num;
        }, 0);
        return numErrors;
      };
    },
    getErrors: function (index) {
      const errors = this.errors.get(index);
      const lastError = errors.pop();

      if (errors.length === 0) {
        return `Há um erro no campo ${lastError}`;
      }

      return `Há erros nos campos ${errors.join(', ')} e ${lastError}`;
    },
    getDepreciationTitle: function (depreciation = {}) {
      const { competencia, projetoId } = depreciation;
      const projetos = this.getProjects(competencia);
      const projeto = projetos.find(({ id }) => projetoId === id);
      const title = projeto?.titulo || 'Título não encontrado';
      const month = competencia ? toMonth(competencia) : 'Sem competência';
      return `${title} — ${month}`;
    },
    getProjects: function (competencia = '') {
      const ano = competencia.substring(0, 4);

      if (ano.length < 4) {
        return [];
      }

      return this.projectsByYear[ano] || [];
    },
    inputHandler: function (index, depre) {
      const items = cloneDeep(this.items);
      items.splice(index, 1, depre);
      this.$emit('input', items);
    },
    openDepreModal: function (item) {
      this.selectedItem = item;
      this.openModal = true;
    },
    setValids: function (index, valid) {
      this.valids.set(index, valid);
    },
  },
  model: {
    event: 'input',
    prop: 'items',
  },
  name: 'depreciation-panels',
  props: {
    canDelete: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
    projectsByYear: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>

<style lang="scss" scoped>
span.has-erros {
  color: red;
}

.icon-btn-title {
  &.v-btn.v-btn--depressed.v-btn--flat.v-btn--icon.v-btn--round.theme--light.v-size--default {
    flex: 0 1 36px;
    pointer-events: all;

    ::before {
      color: #fff;
    }
  } 
}

.btn-plus-depre.v-btn.v-btn--depressed.theme--light.v-size--default {
  background-color: #9abfe6;
  box-shadow: 0px 3px 1px -2px #9abfe6, 0px 2px 2px 0px #9abfe6,
    0px 1px 5px 0px #9abfe6;
  color: #fff;
  float: right;
}

  .v-expansion-panel-header {
    color: #fff;
    justify-content: space-between;
  }
  
  .theme--light.v-expansion-panels
    .v-expansion-panel-header
    .v-expansion-panel-header__icon
    .v-icon {
    color: #fff;
  }
</style>
